import React, { useState, createContext, useEffect } from "react";
import { GROUP_LABELS } from "../constants/constants";

export const DataContext = createContext({});

const groupPopUp = localStorage.getItem("groupPopUp");

const initialCreateGroupAlert = {
  status: groupPopUp || "initial",
  message: (
    <>
      <p>{GROUP_LABELS.alertMessages.planQuestion}</p>
      <p>{GROUP_LABELS.alertMessages.createGroupQuestion}</p>
    </>
  ),
};
export const DataProvider = ({ children }) => {
  const [reservation, setReservation] = useState(null);
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [availibility, setAvailibility] = useState(null);
  const [wellnessActivities, setWellnessActivities] = useState([]);
  const [itineraryData, setItineraryData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [groupData, setGroupData] = useState({ items: [], groupName: "" });
  const [pagesData, setPagesData] = useState([]);
  const [isOnValidState, setIsOnValidState] = useState(false);
  const [isKioskRedirect, setIsKioskRedirect] = useState(false);
  const [isLate, setLate] = useState(false);
  const [dayUseDetails, setDayUseDetails] = useState(null);
  const [treatsData, setTreatsData] = useState(false);
  const [showDiningAddOnsPopup, setShowDiningAddOnsPopup] = useState(false);
  const [activityToBeDeleted, setActivityToBeDeleted] = useState([]);
  const [entertainmentData, setEntertainmentData] = useState([]);
  const [entertainmentStaticContent, setEntertainmentStaticContent] = useState(
    {}
  );
  const [filteredTreatsData, setFilteredTreatsData] = useState([]);
  const [cardBannerContent, setCardBannerContent] = useState({});
  const [showFolio, setShowFolio] = useState(false);
  const [landingPage, setLandingPage] = useState([]);
  const [promotionalSpaceContent, setPromotionalSpaceContent] = useState([]);
  const [
    diningProcessingScreenBannerTexts,
    setDiningProcessingScreenBannerTexts,
  ] = useState([]);
  const [
    promotionalSpaceItineraryExpandedRowsDetails,
    setPromotionalSpaceItineraryExpandedRowsDetails,
  ] = useState([]);
  const [addOnsBookings, setAddOnsBookings] = useState([]);
  const [createGroupAlert, setCreateGroupAlert] = useState(
    initialCreateGroupAlert
  );
  const [allSpaAvailability, setAllSpaAvailability] = useState(null);
  const [singleSpaAvailability, setSingleSpaAvailability] = useState([]);
  const [maintenanceData, setMaintenanceData] = useState({});
  const [booked, setBooked] = useState({});

  const clearOnLogOut = (setView) => {
    setReservation(null);
    setFacilitiesData([]);
    setAvailibility([]);
    setItineraryData(null);
    setSelectedDate(null);
    setGroupData({ items: [], groupName: "" });
    setPagesData([]);
    setIsOnValidState(false);
    setLate(false);
    setActivityToBeDeleted([]);
    setEntertainmentData([]);
    setEntertainmentStaticContent({});
    setCardBannerContent({});
    setView({ type: "" });
    setCreateGroupAlert({
      ...initialCreateGroupAlert,
      status: "initial",
    });
    localStorage.removeItem("groupPopUp");
  };

  return (
    <DataContext.Provider
      value={{
        reservation,
        facilitiesData,
        availibility,
        itineraryData,
        selectedDate,
        isLate,
        isOnValidState,
        activityToBeDeleted,
        groupData,
        pagesData,
        entertainmentData,
        entertainmentStaticContent,
        cardBannerContent,
        allSpaAvailability,
        singleSpaAvailability,
        landingPage,
        diningProcessingScreenBannerTexts,
        promotionalSpaceItineraryExpandedRowsDetails,
        promotionalSpaceContent,
        addOnsBookings,
        createGroupAlert,
        maintenanceData,
        wellnessActivities, 
        setWellnessActivities,
        setMaintenanceData,
        isKioskRedirect, 
        dayUseDetails, 
        setDayUseDetails,
        setIsKioskRedirect,
        setCreateGroupAlert,
        treatsData,
        showDiningAddOnsPopup, 
        filteredTreatsData, 
        setFilteredTreatsData,
        setShowDiningAddOnsPopup,
        setPagesData,
        setGroupData,
        setActivityToBeDeleted,
        setLate,
        setIsOnValidState,
        setSelectedDate,
        setFacilitiesData,
        setAvailibility,
        setReservation,
        setItineraryData,
        setEntertainmentData,
        setEntertainmentStaticContent,
        setCardBannerContent,
        setLandingPage,
        setDiningProcessingScreenBannerTexts,
        setPromotionalSpaceItineraryExpandedRowsDetails,
        setPromotionalSpaceContent,
        setAddOnsBookings,
        setAllSpaAvailability,
        setSingleSpaAvailability,
        clearOnLogOut,
        setTreatsData,
        booked,
        setBooked,
        showFolio, 
        setShowFolio
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
