import React, { useContext, useState } from "react";

import Information from "../../components/InformationAlert/Information";
import TypeCard from "../../components/TypeCard/TypeCard";
import LandingPageLogic from "./LandingPageLogic";
import WindowWidth from "../../hooks/WindowWidth";
import WelcomingModal from "../../components/WelcomingModal/WelcomingModal";
import { DataContext } from "../../context/DataContext";
import { goToPage } from "../../helpingFunctions/linkChange";
import { Link } from "react-router-dom";
import BookingFolio from "../BookingFolio/BookingFolio";

import styles from "./landing-page.module.css";
import { calculteDateDifference } from "../../helpingFunctions/utilities";
import AlertBox from "../../components/AlertBox/AlertBox";
import Button from "../../shared/Button/Button";

export const LandingPage = ({ view, setView }) => {
  const { loading, handleTilesWidth } = LandingPageLogic(view, setView);
  const { width } = WindowWidth();
  const {
    reservation,
    isLate,
    landingPage,
    facilitiesData,
    showFolio,
    setShowFolio,
    booked,
  } = useContext(DataContext);
  const [showCreditCardRegistrationAlert, setShowCreditCardRegistrationAlert] = useState(false);

  const creditCardType = reservation?.CreditCard ? "View your registered card" : "Register your payment card";

  const diference = calculteDateDifference(
    new Date(),
    new Date(reservation?.ArrivalDate)
  );

  const is4by4 = reservation?.Hotel?.TwoLetterCode === "HP";
  const is3by3 = reservation?.IsWithSpa;
  const is3by2 = !is4by4 && !is3by3;
  const showWellnessActivities =
    (reservation?.RateType[0].toLowerCase() === "w" &&
      !isNaN(reservation?.RateType[1])) ||
    reservation?.RateType === "wb hb";
  const hasTreats =
    facilitiesData.filter((row) => row.objType === "Treats").length > 0;

  return (
    <>
      <WelcomingModal setView={setView} />
      {loading ? (
        <div style={{ minHeight: "100%" }}>Loading...</div>
      ) : (
        <>
          {width <= 1324 ? (
            <Information
              width={width >= 700 && width <= 1324 ? "80%" : "95.327%"}
              height={"auto"}
              backgroundColor={booked.backgroundColor}
              txt={booked.txt}
              lineBackgroundColor={booked.lineBackgroundColor}
              icon={booked.icon}
              marginLeft={"15px"}
              pageWidth={width}
            />
          ) : null}
          <div className={styles.landingPageWrapper}>
            <TypeCard
              paddingBottom={"20px"}
              width={"100%"}
              height={"135px"}
              setView={setView}
              src={reservation?.Hotel?.Image?.large?.url}
              type={"itinerary"}
              txt={`Your ${reservation.Hotel.Title} itinerary`}
            />
            <div className={styles.secondRow}>
              {width > 1324 ? (
                <Information
                  width={handleTilesWidth(
                    is4by4,
                    is3by3,
                    is3by2,
                    width > 1324,
                    "Info"
                  )}
                  height={"260px"}
                  backgroundColor={booked.backgroundColor}
                  lineBackgroundColor={booked.lineBackgroundColor}
                  txt={booked.txt}
                  icon={booked.icon}
                  landingPage={booked.backgroundColor === "#2f5c27"}
                  pageWidth={width}
                />
              ) : null}

              <TypeCard
                paddingBottom={"20px"}
                width={handleTilesWidth(
                  is4by4,
                  is3by3,
                  is3by2,
                  width > 1324,
                  "Dining"
                )}
                height={"260px"}
                setView={setView}
                type={"Dining"}
                src={landingPage?.DiningImage?.formats?.medium?.url}
                txt={landingPage.DiningText}
              />
              {is4by4 ? (
                <TypeCard
                  paddingBottom={"20px"}
                  width={handleTilesWidth(
                    is4by4,
                    is3by3,
                    is3by2,
                    width > 1324,
                    "Entertainment"
                  )}
                  height={"260px"}
                  type={"Entertainment"}
                  setView={setView}
                  src={landingPage?.EntertainmentImage?.formats?.medium?.url}
                  txt={landingPage.EntertainmentText}
                />
              ) : null}
            </div>
            <div className={styles.thirdRow}>
              {is4by4 || is3by3 ? (
                <TypeCard
                  paddingBottom={"20px"}
                  width={handleTilesWidth(
                    is4by4,
                    is3by3,
                    is3by2,
                    width > 1324,
                    "Spas"
                  )}
                  height={"245px"}
                  setView={
                    reservation.IsWithSpa
                      ? setView
                      : goToPage.bind(
                          this,
                          "https://www.warnerleisurehotels.co.uk/breaks/spa-breaks"
                        )
                  }
                  type={"Spas"}
                  src={reservation?.Hotel?.SpaTileImg?.formats?.medium?.url}
                  txt={reservation.Hotel.SpaLabel}
                />
              ) : null}
              {is4by4 ? (
                <TypeCard
                  paddingBottom={"20px"}
                  width={handleTilesWidth(
                    is4by4,
                    is3by3,
                    is3by2,
                    width > 1324,
                    "Golf"
                  )}
                  height={"245px"}
                  type={"Golf"}
                  setView={setView}
                  src={landingPage?.GolfImage?.formats?.medium?.url}
                  txt={landingPage.GolfText}
                />
              ) : null}
              <TypeCard
                paddingBottom={"20px"}
                width={handleTilesWidth(
                  is4by4,
                  is3by3,
                  is3by2,
                  width > 1324,
                  "Activities"
                )}
                height={"245px"}
                setView={setView}
                type={"Activities"}
                src={landingPage.ActivitesImage?.formats?.medium?.url}
                txt={landingPage.ActivitesText}
              />
              <TypeCard
                paddingBottom={"20px"}
                width={handleTilesWidth(
                  is4by4,
                  is3by3,
                  is3by2,
                  width > 1324,
                  "Treats"
                )}
                height={"245px"}
                setView={hasTreats ? setView : {}}
                type={hasTreats ? "Treats" : null}
                src={landingPage.TreatsImage?.formats?.medium?.url}
                txt={
                  hasTreats
                    ? landingPage.TreatsText
                    : `${landingPage.TreatsText} (Coming soon)`
                }
                hideItem={isLate.isLate}
              />
              <TypeCard
                paddingBottom={"20px"}
                setView={setView}
                type={"WhatsOnGuide"}
                height={"245px"}
                src={landingPage.WhatsOnImage?.formats?.medium?.url}
                txt={landingPage.WhatsOnText}
                width={handleTilesWidth(
                  is4by4,
                  is3by3,
                  is3by2,
                  width > 1324,
                  "third",
                  true
                )}
              />
              {showWellnessActivities && (
                <TypeCard
                  setView={setView}
                  paddingBottom={"20px"}
                  type={"WellnessActivities"}
                  height={"245px"}
                  src={landingPage.WellnessActivityImage?.formats?.medium?.url}
                  txt={landingPage.WellnessActivityText}
                  width={handleTilesWidth(
                    is4by4,
                    is3by3,
                    is3by2,
                    width > 1324,
                    "third",
                    true
                  )}
                />
              )}
              {new Date().toISOString().split("T")[0] >=
                reservation.ArrivalDate.split("T")[0] && (
                <TypeCard
                  onClick={() => setShowFolio(true)}
                  paddingBottom={"20px"}
                  type={"Folio"}
                  height={"245px"}
                  src={landingPage.RoomAccountImage?.formats?.medium?.url}
                  txt={landingPage.RoomAccountText}
                  width={handleTilesWidth(
                    is4by4,
                    is3by3,
                    is3by2,
                    width > 1324,
                    "third",
                    true
                  )}
                />
              )}
              {process.env.REACT_APP_PXP_ENABLED !== "0" && 
                reservation.Hotel.ShowCreditCardTile && (new Date() > new Date(reservation.ArrivalDate) || diference?.allDayDifference <= 28) && (
                <TypeCard
                paddingBottom={"20px"}
                height={"245px"}
                type={"CreditCard"}
                src={landingPage.CreditCardImage?.formats?.medium?.url}
                txt={landingPage.CreditCardText}
                onClick={() => {
                  if (diference) {
                    if(diference.allDayDifference <= 28) {
                      setView({
                        type: "",
                        modalPage: creditCardType,
                        stopRequests: true,
                      });
                    } else {
                      setShowCreditCardRegistrationAlert(true);
                    }
                  } else {
                    setView({
                      type: "",
                      modalPage: creditCardType,
                      stopRequests: true,
                    });
                  }
                }}
                width={handleTilesWidth(
                  is4by4,
                  is3by3,
                  is3by2,
                  width > 1324,
                  "third",
                  true
                )}
              />
              )}
              {reservation.Hotel.HotelMapImage && (
                <TypeCard
                setView={setView}
                paddingBottom={"20px"}
                type={"HotelMapImage"}
                height={"245px"}
                src={landingPage.MapImage?.formats?.medium?.url}
                txt={landingPage.MapText}
                width={handleTilesWidth(
                  is4by4,
                  is3by3,
                  is3by2,
                  width > 1324,
                  "third",
                  true
                )}
              />
              )}
            </div>
          </div>
        </>
      )}

      {showFolio && <BookingFolio />}
      {showCreditCardRegistrationAlert &&
        <AlertBox 
          txt={"Oops it looks like you're trying to register your card too early. You can register your card 4 weeks before arrival."} 
          button={
            <>
              <Button
                txt={"Ok"}
                color={"var(--color-secondary)"}
                backgroundColor={"var(--color-primary)"}
                onClick={() => setShowCreditCardRegistrationAlert(false)}
              />
            </>
          }
        />
      }
    </>
  );
};

export default LandingPage;
